import React, { useState } from 'react';
import ReactDOM from 'react-dom';

interface ResponseRow {
  datasetid: string;
  name: string;
  count: number;
}

interface TaskParameters {
  domain: string;
  daysAgo: 1;
}

const RollupsSuggestionForm = () => {
  const [responseTableRows, setResponseTableRows] = useState<ResponseRow[]>([]);
  const [rollupTaskParameters, setRollupTaskParameters] = useState<TaskParameters>({
    domain: '',
    daysAgo: 1
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [suggestionsExpected, setSuggestionsExpected] = useState(false);
  const [isLoadingSuggestedRollups, setIsLoadingSuggestedRollups] = useState(false);
  const [getRollupSuggestionsError, setGetRollupSuggestionsError] = useState(null);
  const [selectedRollups, setSelectedRollups] = useState<number[]>([]);
  const [deleteJobDone, setdeleteJobDone] = useState<boolean | null>(null);
  const [isDeletingRollups, setIsDeletingRollups] = useState(false);

  const itemsPerPage = 3;
  const noSuggestions = responseTableRows?.length === 0;

  async function pollForResults(taskId: string) {
    let completed = false;
    let response = null;

    while (!completed) {
      const taskResponse = await fetch(
        '/internal/domains/${window.location.hostname}/retrieve_dia_task_result?taskId=${taskId}',
        {
          method: 'GET'
        }
      );
      const data = await taskResponse.json();

      if (data.response.status === 'Done') {
        completed = true;
        response = data.response;
      } else if (data.response.status === 'Failed') {
        console.log('Task info', response);
        throw new Error('Rollup job failed');
      } else {
        await new Promise((resolve) => setTimeout(resolve, 30 * 1000));
      }
    }
    return response;
  }

  const handleSubmitSuggestRollup = async (e: React.FormEvent) => {
    setIsLoadingSuggestedRollups(true);
    setGetRollupSuggestionsError(null);
    setResponseTableRows([]);
    setdeleteJobDone(null);
    setSuggestionsExpected(false);
    e.preventDefault();
    try {
      const body = new FormData();
      body.append(
        'authenticity_token',
        document.querySelector('meta[name="csrf-token"]')!.getAttribute('content')!
      );
      body.append('commit', 'Suggest Rollups');
      Object.keys(rollupTaskParameters).forEach((key) => {
        body.append(key, rollupTaskParameters[key]);
      });
      const startResponse = await fetch(
        `/internal/domains/${window.location.hostname}/suggest_rollups_for_deletion`,
        {
          method: 'POST',
          body
        }
      );
      const data: { taskId?: string } = await startResponse.json();
      if (!data.taskId) {
        throw new Error('Problem starting suggest job, no task id returned');
      }

      const response = await pollForResults(data.taskId);

      const suggestedRollups = JSON.parse(response.result).suggestedRollups;
      setResponseTableRows(suggestedRollups);
      setCurrentPage(1);
      setSuggestionsExpected(true);
    } catch (error) {
      setGetRollupSuggestionsError(error.message);
    } finally {
      setIsLoadingSuggestedRollups(false);
    }
  };

  const handleSubmitDeleteRollup = async (deleteAllSuggestedRollups: boolean) => {
    setIsDeletingRollups(true);
    setSuggestionsExpected(false);
    const body = new FormData();
    body.append(
      'authenticity_token',
      document.querySelector('meta[name="csrf-token"]')!.getAttribute('content')!
    );
    body.append('commit', 'Delete Rollups');
    Object.keys(rollupTaskParameters).forEach((key) => {
      body.append(key, rollupTaskParameters[key]);
    });
    try {
      if (selectedRollups.length !== responseTableRows.length && !deleteAllSuggestedRollups) {
        const rollupDatasets = [];
        const rollupNames = [];
        // For deletes, link the rollup name and its dataset by index
        for (let i = 0; i < selectedRollups.length; i++) {
          const datasetid = responseTableRows[selectedRollups[i]].datasetid;
          const name = responseTableRows[selectedRollups[i]].name;
          rollupDatasets.push(datasetid);
          rollupNames.push(name);
        }

        rollupDatasets.forEach((rollupDataset) => {
          body.append('rollupDatasets[]', rollupDataset);
        });
        rollupNames.forEach((rollupName) => {
          body.append('rollupNames[]', rollupName);
        });
      }
      const startResponse = await fetch(
        '/internal/domains/${window.location.hostname}/apply_rollup_deletion_suggestions',
        {
          method: 'POST',
          body
        }
      );
      const data: { taskId?: string } = await startResponse.json();
      if (!data.taskId) {
        throw new Error('Problem starting apply job, no task id returned');
      }
      // Don't bother with the result, a failed result will throw an error
      await pollForResults(data.taskId);
      setdeleteJobDone(true);
    } catch (error) {
      console.log('error', error);
      setdeleteJobDone(false);
    } finally {
      setIsDeletingRollups(false);
    }
  };

  let totalPages = 0;
  let currentItems: ResponseRow[] = [];
  let multiPageTable = true;
  const startIndex = (currentPage - 1) * itemsPerPage;
  if (responseTableRows) {
    totalPages = Math.ceil(responseTableRows.length / itemsPerPage);
    if (totalPages === 1) {
      multiPageTable = false;
    }
    currentItems = responseTableRows.slice(startIndex, startIndex + itemsPerPage);
  }

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  function handleFormChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    setRollupTaskParameters({
      ...rollupTaskParameters,
      [name]: value
    });
  }

  const handleCheckingSuggestions = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    if (target.checked) {
      setSelectedRollups([...selectedRollups, parseInt(target.value)]);
    } else {
      setSelectedRollups(selectedRollups.filter((rollup) => rollup !== parseInt(target.value)));
    }
  };

  return (
    <div style={{ minHeight: 250 }}>
      {isLoadingSuggestedRollups ? (
        <div>Loading ... </div>
      ) : (
        <form onSubmit={handleSubmitSuggestRollup}>
          <label>
            Domain:
            <input
              type="text"
              name="domain"
              value={rollupTaskParameters.domain}
              onChange={handleFormChange}
            />
          </label>
          <br />
          <label>
            Days Ago:
            <input
              type="number"
              name="daysAgo"
              value={rollupTaskParameters.daysAgo}
              onChange={handleFormChange}
            />
          </label>
          <br />
          <button type="submit">Suggest Rollups</button>
        </form>
      )}
      {getRollupSuggestionsError && <div>The following error occured: {getRollupSuggestionsError}</div>}
      {suggestionsExpected && noSuggestions ? (
        <div>No rollups suggested for this domain during this time period</div>
      ) : (
        suggestionsExpected &&
        responseTableRows &&
        deleteJobDone === null && (
          <div>
            {isDeletingRollups ? (
              <div
                style={{
                  background: '#d4d4f3',
                  width: 'max-content',
                  padding: '5px 10px',
                  borderRadius: '5px'
                }}
              >
                Deleting the selected rollups ...
              </div>
            ) : (
              <div>
                <button onClick={() => handleSubmitDeleteRollup(false)}>Delete Selected Rollups</button>
                <button onClick={() => handleSubmitDeleteRollup(true)}>Delete All Rollups</button>
              </div>
            )}
            <table>
              <thead>
                <tr>
                  <th>Dataset ID</th>
                  <th>Name</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((row, index) => (
                  <tr key={(currentPage - 1) * itemsPerPage + index}>
                    <td>{row.datasetid}</td>
                    <td>{row.name}</td>
                    <td>{row.count}</td>
                    <td>
                      <input
                        value={(currentPage - 1) * itemsPerPage + index}
                        type="checkbox"
                        checked={selectedRollups.includes((currentPage - 1) * itemsPerPage + index)}
                        onChange={handleCheckingSuggestions}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
              {multiPageTable && (
                <div>
                  <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                    Previous Page
                  </button>
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>
                  <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                    Next Page
                  </button>
                </div>
              )}
            </table>
          </div>
        )
      )}
      {deleteJobDone === true && (
        <div
          style={{
            background: '#dbf7db',
            width: 'max-content',
            padding: '5px 10px',
            borderRadius: '5px'
          }}
        >
          The selected rollup(s) have been successfully deleted
        </div>
      )}
      {deleteJobDone === false && <div>An issue came up while deleting the selected rollup(s)</div>}
    </div>
  );
};

ReactDOM.render(<RollupsSuggestionForm />, document.querySelector('#rollup-deletion-app'));
